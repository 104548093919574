import {translateToUK} from 'international';
import {isUK} from 'international';

window.addEventListener('load', checkForTranslation);
document.addEventListener('turbolinks:load', checkForTranslation); // Turbolinks 5

function checkForTranslation(){
  if (isUK()){
    translateToUK();
  }
}

