const current_domain = window.location.hostname;

export function currentDomain(){
  return current_domain;
}

export function country(){
  if (current_domain == 'textsfromsanta.co.uk' || current_domain == 'dev.tfs.uk.ngrok.io'){
     return 'UK';
   }
   else {
    return 'US';
  }
}

export function isUK(){
  var uk_override = document.getElementById('uk_override');
  if (uk_override) {
    return true;
  }
  else {
    return country() == 'UK';
  }
}

export function getPrice(amount){
  if (isUK() && amount != 0){
    return Math.floor(amount * 0.77) + .95;
  }
  else {
    return amount;
  }
}

export function paymentSymbol(){
  if (isUK()){
    return '£';
  }
  else {
    return '$';
  }
}

export function translateToUK(){
  console.log('running translateToUK');
  var telephone = document.getElementById('tfs_telephone');
  console.log(telephone);
  if (telephone) {
    console.log('running tele translateToUK');
    telephone.href='tel://01749372118';
    telephone.innerHTML='01749 372118';
  }

  var send_types = document.getElementById('order_send_to_type'), send_type, i;

  if (send_types){
    for(i = 0; i < send_types.length; i++) {
      send_type = send_types[i];
      if (send_type.innerHTML == 'Cell Phone'){
        send_type.innerHTML = 'Mobile Phone';
      }
    }
  }

  var personal = document.getElementById('personal_header');
  if (personal) {
    console.log('p');
    personal.innerHTML = '2020 Personalised Texts & Photos';
  }
  
  var personal_td = document.getElementById('personal_td');
  if (personal_td) {
    console.log('p_td');
    personal_td.innerHTML = 'Personalised REAL Video from Santa';
  }
  
  var extra_25 = document.getElementById('extra_25');
  var extra_75 = document.getElementById('extra_75');
  if (extra_25) {extra_25.innerHTML = '+ ' + paymentSymbol() + '25 worth of Extras!';}
  if (extra_75) {extra_75.innerHTML = '+ ' + paymentSymbol() + '75 worth of Extras!';} 

  var mobile_logo = document.getElementById('mobile_logo_a');
  if (mobile_logo) {
    mobile_logo.classList.add('uk');
  }
  mobile_logo = document.getElementById('mobile_logo_b');
  if (mobile_logo) {
    mobile_logo.classList.add('uk');
  }
}
